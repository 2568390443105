<template>
  <div>
    <SiteHeader></SiteHeader>

    <div class="topbar text-center">
      <strong class="fs-1">{{ detail.description }}</strong>
      <div class="menu">
        <div
          v-for="item in alllanmu"
          :key="item.id"
          @click="todetail(item.id)"
          :class="{ alink: true, current: currentid === item.id }"
        >
          {{ item.title }}
        </div>
      </div>
    </div>

    <div class="container">
      <div
        v-if="detail.title !== 'Contact'"
        class="pageContent"
        v-loading="isLoading"
      >
        <div class="htmlContent" v-html="detail.content"></div>
      </div>

      <div
        v-if="detail.title === 'Contact'"
        class="page content clearfix mt-3 mb-3"
      >
        <div class="jumbotron border p-3">
          <h1 class="display-4 fs-4">Guest Book.</h1>
          <p class="lead" style="text-align: left">
            If you have questions, we suggest you submit them here and we will
            respond as soon as possible.
          </p>
          <hr class="my-4" />

          <div class="input-group mb-4">
            <span class="input-group-text">Question</span>
            <input
              type="text"
              class="form-control"
              v-model="question"
              required
            />
          </div>

          <div class="form-row mb-5">
            <div
              class="form-group col-md-12 mb-3"
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              "
            >
              <label style="text-align: left" for="yzm"
                ><i class="bi-bullseye"></i> Verification</label
              >

              <div class="verifybox">
                <el-row class="mt-2" style="border-radius: 10px; width: 100%">
                  <drag-verify
                    ref="dragVerify"
                    class="full-width border"
                    :isPassing.sync="isPassing"
                    text="请按住滑块拖动"
                    successText="验证通过"
                    handlerIcon="el-icon-d-arrow-right"
                    successIcon="el-icon-circle-check"
                    @passcallback="passcallback"
                  >
                    <i
                      v-show="!isPassing"
                      slot="textBefore"
                      class="el-icon-lock"
                    ></i>
                  </drag-verify>
                </el-row>
              </div>
            </div>
          </div>
          <div style="display: flex">
            <button
              class="btn btn-danger ajax-submit"
              @click="tosubmit"
              type="button"
            >
              Submit
            </button>
            <button
              type="reset"
              class="btn btn-light"
              style="margin-left: 10px"
              @click="reset"
            >
              Rest
            </button>
          </div>
        </div>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";

//https://api.macnninc.com/api/frontend/single-page/all-lanmu

import Foot from "@/components/website/Foot.vue";
import { alllanmu, question, singlepage } from "@/api/index.js";

export default {
  name: "PcWebsiteIndex",

  components: {
    SiteHeader,
    Foot,
  },

  data() {
    return {
      isLoading: false,
      detail: {},
      alllanmu: [],
      currentid: 1,
      isPassing: false,
      question: "",
    };
  },

  mounted() {
    this.isLoading = true;
    alllanmu().then((res) => {
      this.alllanmu = res.data.header;
      this.isLoading = false;
      this.currentid = this.alllanmu[0].id;
      this.todetail(this.alllanmu[0].id);
    });
  },
  methods: {
    todetail(id) {
      // console.log('id',id)
      this.$nextTick(() => {
        this.alllanmu.map((item) => {
          if (item.id == id) {
            this.currentid = item.id;
            this.detail = item;
          }
        });
      });
    },

    tosubmit() {
      const params = {
        question: this.question,
      };

      if (this.isPassing && this.question !== "") {
        question(params).then((res) => {
          this.$message.success("successfully");
          this.reset();
        });
      } else {
        this.isPassing = false;
        this.$refs.dragVerify.reset();
        this.$message({
          message: "Verification failed",
          type: "warning",
        });
      }
    },

    passcallback() {
      this.isPassing = true;
    },

    reset() {
      this.question = "";
      this.isPassing = false;
      this.$refs.dragVerify.reset();
    },
    toCart() {
      this.$router.push("/cart");
    },    
    toAbout() {
      this.$router.push("/about");
    },
    toCopyright() {
      this.$router.push("/copyright");
    },
    toFaq() {
      this.$router.push("/contact");
    },
    toBankinfo() {
      this.$router.push("/bankinfo");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.verifybox {
  display: flex;
}

.full-width {
  width: 100%;
}
</style>
